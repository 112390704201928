import i18n from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

import { WaterlyLocals } from "./locales";

i18n.use(initReactI18next).use(
  resourcesToBackend(async (language: string, namespace: string) => {
    return import(`./locales/${language}/${namespace}.json`);
  }),
);

const postProcess = [];
if (localStorage.getItem("test-LANG")) {
  i18n.use({
    type: "postProcessor",
    name: "testLang",
    process: () => "~~~~",
  });
  postProcess.push("testLang");
}

const local = localStorage.getItem("locale") ?? WaterlyLocals.enUS;

void i18n.init({
  postProcess,
  lng: local,
  load: "currentOnly",
  fallbackLng: WaterlyLocals.enUS,
  defaultNS: ["glossary", "common"],
  ns: [
    "DataGridColumnHeaders",
    "EventTypeEnum",
    "EventTypeGroupEnum",
    "RecurrenceTypeEnum",
    "SiteTypeEnum",
    "SystemTypeEnum",
    "WaterlyLocals",
    "admin",
    "common",
    "errors",
    "glossary",
    "months",
    "role",
  ],
  interpolation: {
    escapeValue: false, // react already safe from xss
    format: (value: string, format) => {
      if (format === "lowercase") {
        return value.toLocaleLowerCase();
      }

      return value;
    },
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "em", "span"],
  },
  debug: process.env.NODE_ENV === "development",
});

export default i18n;
